const dayjsTranslations = {
  'en-US': {
    name: 'en', // name String
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], // weekdays Array
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    ordinal: n => `${n}º`, // ordinal Function (number) => return number + output
    formats: {
      // abbreviated format options allowing localization
      LTS: 'h:mm:ss A',
      LT: 'h:mm A',
      L: 'MM/DD/YYYY',
      LL: 'MMMM D, YYYY',
      LLL: 'MMMM D, YYYY h:mm A',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      // lowercase/short, optional formats for localization
      l: 'D/M/YYYY',
      ll: 'D MMM, YYYY',
      lll: 'D MMM, YYYY h:mm A',
      llll: 'ddd, MMM D, YYYY h:mm A',
    },
    relativeTime: {
      // relative time format strings, keep %s %d as the same
      future: 'in %s', // e.g. in 2 hours, %s been replaced with 2hours
      past: '%s ago',
      s: 'a few seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours', // e.g. 2 hours, %d been replaced with 2
      d: 'a day',
      dd: '%d days',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
    meridiem: hour => {
      // OPTIONAL, AM/PM
      return hour > 12 ? 'PM' : 'AM';
    },
  },
  'es-MX': {
    name: 'es', // name String
    weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'], // weekdays Array
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    ordinal: n => `${n}º`, // ordinal Function (number) => return number + output
    formats: {
      // abbreviated format options allowing localization
      LTS: 'h:mm:ss A',
      LT: 'h:mm A',
      L: 'MM/DD/YYYY',
      LL: 'MMMM D, YYYY',
      LLL: 'MMMM D, YYYY h:mm A',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      // lowercase/short, optional formats for localization
      l: 'D/M/YYYY',
      ll: 'D MMM, YYYY',
      lll: 'D MMM, YYYY h:mm A',
      llll: 'ddd, MMM D, YYYY h:mm A',
    },
    relativeTime: {
      // relative time format strings, keep %s %d as the same
      future: 'en %s', // e.g. in 2 hours, %s been replaced with 2hours
      past: 'hace %s',
      s: 'unos segundos',
      m: 'un minuto',
      mm: '%d minutos',
      h: 'una hora',
      hh: '%d horas', // e.g. 2 hours, %d been replaced with 2
      d: 'un día',
      dd: '%d días',
      M: 'un mes',
      MM: '%d meses',
      y: 'un año',
      yy: '%d años',
    },
    meridiem: hour => {
      // OPTIONAL, AM/PM
      return hour > 12 ? 'PM' : 'AM';
    },
  },
  'fr-FR': {
    name: 'fr', // name String
    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'], // weekdays Array
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    ordinal: n => `${n}º`, // ordinal Function (number) => return number + output
    formats: {
      // abbreviated format options allowing localization
      LTS: 'HH:mm:ss',
      LT: 'HH:mm',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm',
      // lowercase/short, optional formats for localization
      l: 'D/M/YYYY',
      ll: 'D MMM YYYY',
      lll: 'D MMM YYYY HH:mm',
      llll: 'ddd D MMM YYYY HH:mm',
    },
    relativeTime: {
      // relative time format strings, keep %s %d as the same
      future: 'dans %s', // e.g. in 2 hours, %s been replaced with 2hours
      past: 'il y a %s',
      s: 'quelques secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures', // e.g. 2 hours, %d been replaced with 2
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans',
    },
    meridiem: hour => {
      // OPTIONAL, AM/PM
      return hour > 12 ? 'PM' : 'AM';
    },
  },
  'pt-BR': {
    name: 'pt', // name String
    weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'], // weekdays Array
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    ordinal: n => `${n}º`, // ordinal Function (number) => return number + output
    formats: {
      // abbreviated format options allowing localization
      LTS: 'HH:mm:ss',
      LT: 'HH:mm',
      L: 'DD/MM/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY [às] HH:mm',
      LLLL: 'dddd, D [de] MMMM [de] YYYY [às] HH:mm',
      // lowercase/short, optional formats for localization
      l: 'DD/MM/YYYY',
      ll: 'D [de] MMM [de] YYYY',
      lll: 'D [de] MMM [de] YYYY [às] HH:mm',
      llll: 'ddd, D [de] MMM [de] YYYY [às] HH:mm',
    },
    relativeTime: {
      // relative time format strings, keep %s %d as the same
      future: 'em %s', // e.g. in 2 hours, %s been replaced with 2hours
      past: '%s atrás',
      s: 'alguns segundos',
      m: 'um minuto',
      mm: '%d minutos',
      h: 'uma hora',
      hh: '%d horas', // e.g. 2 hours, %d been replaced with 2
      d: 'um dia',
      dd: '%d dias',
      M: 'um mês',
      MM: '%d meses',
      y: 'um ano',
      yy: '%d anos',
    },
    meridiem: hour => {
      // OPTIONAL, AM/PM
      return hour > 12 ? 'PM' : 'AM';
    },
  },
};

export default dayjsTranslations;
